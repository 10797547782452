import React, {useMemo} from "react";
import MyButton from "../../../_utility-components/button/button";
import { OrdersFilters } from "../dto/filters";
import useOrderFilters from "../hooks/use-order-filters";
import {FilterItem} from "../../../../scripts/utils/entity-filters";

export type FilterTabRules = [ Array<number>, (keyof OrdersFilters) | undefined ]

interface FilterTabProps {
  handler: (rules: FilterTabRules) => void,
  text: string,
  rules: FilterTabRules
}

export const FilterTab = ({
  text,
  handler,
  rules
}: FilterTabProps) => {
  const [ordersFilters] = useOrderFilters();

  let isActive = useMemo(() => {
    try {
      if(!ordersFilters) {
        return false
      }

      const filter = rules[1] && ordersFilters[rules[1]];

      if(filter) {
        return Boolean(
          filter
            .filter(
              f =>
                rules[0].some(
                  filterId =>
                    filterId === f.id && f.selected
                )
            ).length
        )
      }

      return (
        Object.entries(ordersFilters)
          .every((s: [string, FilterItem[]]) =>
              s[1] && s[1].every(
                r => !r.selected
              )
          )
      )
    } catch (error) {
      return false
    }
  }, [ordersFilters, rules])

  if(!ordersFilters) {
    return null
  }

  return (
    <MyButton className={isActive ? "active-status" : ''} 
      removeDefaultClass
      onClick={() => handler(rules)}
    >
      {text}
    </MyButton>
  )
}