import React, { useContext, useEffect } from 'react';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { useTabIndex } from '../../../../../scripts/hooks/use-tabindex';
import MyInput from '../../../../_utility-components/input/index';
import { OrderDelivery } from '../../dto/delivery';
import { OrderDeliveryContext } from './delivery';

function CourierDelivery () {
  const {
    delivery,
    changeSelectedDelivery,
  } = useContext(OrderDeliveryContext)

  const tabIndex = useTabIndex()
  const settings = useAppSelector(state => state.settings)

  let courierPostFixPrice = settings.find((s) => s.callname === "courier_post_fix_price"); 

  useEffect(() => {

    changeSelectedDelivery({
      // point_id: order.point_id || '',
      // address: order.address || '',
      // delivery_city: order.delivery_city || '',
      cost: (courierPostFixPrice?.value as number | undefined) || 0,
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const labelChanger = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    label: keyof OrderDelivery
  ) => {
    changeSelectedDelivery({[label]: e.target.value})
  }

  return (
    <div className="op-delivery-simple op-delivery-extended">
      <div className="title-div">адрес доставки</div>
      <div className="delivery-address-inputs">
        <MyInput 
          name="delivery-city" 
          myClassName='delivery-city-input'
          placeholder="Наименование города" 
          value={delivery?.city || ''} 
          onChange={(e) => labelChanger(e, 'city')} 
          tabIndex={tabIndex}/>

        <MyInput 
          name="delivery-postcode" 
          myClassName='delivery-postcode-input'
          placeholder="Индекс" 
          value={delivery?.postcode || ''} 
          onChange={(e) => labelChanger(e, 'postcode')} 
          tabIndex={tabIndex}/> 

        <MyInput 
          name="delivery-address" 
          myClassName='delivery-address-input'
          placeholder="Адрес" 
          value={delivery?.address || ''} 
          onChange={(e) => labelChanger(e, 'address')} 
          tabIndex={tabIndex}/>
      </div>
    </div>
  );
}

export default CourierDelivery