import React, {useEffect, useState} from 'react';
import MyInput, { PasswordInput } from '../_utility-components/input/index';
import {addMessage, toastUp, validateResponse} from '../../scripts/functions';
import MyButton from '../_utility-components/button/button';
import qs  from 'qs';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import history from '../../scripts/history';
import { useLoad } from '../../scripts/hooks/use-load';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import useMounted from "../../scripts/hooks/use-mounted";

function PasswordRecoveryPassword ({

}: RouteComponentProps) {
  const _isMounted = useMounted();

  const [token, setToken] = useState('')
  const [newPassword, setNewPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const [, setLoad] = useLoad()
  const location = useLocation()

  useEffect(() => {
    const withoutQuestionChar = location.search.substring(1)
    const token = qs.parse(withoutQuestionChar).token;

    if(typeof token === 'string') {
      setToken(token)
    }
  }, [])

  const sendRequest = () => {
    console.log('token', token)
    if(!token) {
      return false
    }

    console.log('newPassword', newPassword)
    console.log('repeatedPassword', repeatedPassword)
    if( newPassword !== repeatedPassword) {
      addMessage('.sign-in-form .new-password .merchery-label__inner', 'Пароли отличаются')
      return false
    }

    setLoad(true)

    mercheryFetch('auth/new-password', 'POST', {
      token: token,
      password: newPassword
    })
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      history.push(`/sign-in`)
    })
    .finally(() => {
      setLoad(false)
    })
  }

  return (
    <div id="recovery" className="sign-in-form recovery">
      <h1>Новый пароль</h1>

      <PasswordInput
        name="new-password"
        placeholder="Введите новый пароль" 
        onChange={e => setNewPassword(e.target.value)} 
        value={newPassword}
        onEnter={sendRequest}
      />

      <MyInput 
        name="repeat-password" 
        type={'password'}
        placeholder="Повторите новый пароль" 
        onChange={e => setRepeatedPassword(e.target.value)} 
        value={repeatedPassword}
        onEnter={sendRequest}
      />

      <MyButton 
        className="blue-btn" 
        onClick={sendRequest}
      >
        Сменить пароль
      </MyButton>

      <Link 
        className="password-recovery-link" 
        to="/sign-in"
      >
        Вернуться на страницу входа
      </Link>
    </div>
  );
}

export default PasswordRecoveryPassword